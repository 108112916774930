<template>
  <section class="auctions__wrap">
    <v-treeview
      v-if="filteredItems.length"
      class="auctions__tree"
      rounded
      hoverable
      open-all
      transition
      dense
      :items="filteredItems"
    >
      <template v-slot:label="{ item }">
        <div
          class="d-flex justify-space-between pt-1 pb-1"
          :class="{ 'specific-name': item.type === 'area' || 'auction' }"
        >
          <div class="d-flex">
            <div class="text-primary auctions__date" v-if="item.date">
              <span
                class="cursor-pointer"
                @click="handlerClickOnDate(item.date)"
                >{{ item.date | toDate }}</span
              >
            </div>
            <div :style="dynamicColor(item.type)">
              <v-icon class="mr-2" color="success" v-if="item.icon">{{
                item.icon
              }}</v-icon>
              <div v-if="item.type === 'date'">
                <div
                  class="d-flex flex-column mb-3"
                  v-for="(auction, idx) in item.data"
                  :key="idx"
                >
                  <v-divider class="mb-3" v-if="idx" />
                  <div>
                    <span
                      >{{ auction.landArea }} Га;
                      {{ auction.cost }} грн.; </span
                    ><a
                      class="text-decoration-underline"
                      :href="auction.link"
                      target="_blank"
                      >{{ auction.cadastralNumber }}
                    </a>
                  </div>
                  <span class="font-weight-bold"
                    >{{ auction.cvzuId }} {{ auction.cvzuText }}</span
                  >
                  <span>{{ auction.kvtspzId }} {{ auction.kvtspzText }} </span>
                </div>
              </div>
              <span v-else
                >{{ item.name }}
                {{
                  (item.children &&
                    item.children.length &&
                    `(${item.children.length})`) ||
                  ''
                }}</span
              >
            </div>
          </div>
          <span
            class="text-secondary"
            v-if="
              item.type === 'area' && item.children && !item.children.length
            "
            >Аукціони відсутні</span
          >
          <span
            class="text-secondary"
            v-else-if="
              item.type === 'auction' && item.children && !item.children.length
            "
            >Відсутні</span
          >
        </div>
      </template>
    </v-treeview>
    <div v-else class="pa-3">
      <span class="text-secondary">Будь ласка, перейдіть на вкладку "календар"</span>
    </div>
  </section>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { mapActions, mapGetters } from 'vuex'
import mixinsDynamicStyles from '../mixins'

export default {
  name: 'TreeComponent',
  mixins: [mixinsDynamicStyles],
  data: () => ({
    filteredItems: []
  }),
  computed: {
    ...mapGetters('map', ['getAuctions'])
  },
  methods: {
    ...mapActions('map', ['fetchAuctions']),
    handlerClickOnDate (date) {
      this.$router.replace({
        query: {
          auctionView: 'calendar',
          date: new Date(date).toISOString().split('T')[0]
        }
      })
    }
  },
  mounted () {
    this.fetchAuctions().then(() => {
      this.filteredItems = cloneDeep(this.getAuctions)
      setTimeout(() => {
        const areas = document.querySelectorAll('.area')
        areas.forEach((val, idx) => {
          if (!(idx % 2)) {
            val.parentElement.parentElement.parentElement.style.background =
              '#e1f8eb'
          }
        })
      }, 200)
    })
  }
}
</script>
<style lang="scss">
.auctions {
  &__wrap {
    overflow: auto;
    width: 959px;
    @media (max-width: 599px) {
      width: 470px;
    }
  }
  &__tree {
    width: 959px;
  }
  .specific-name {
    max-width: 350px;
  }
  &__date {
    width: 100px;
  }
}
</style>
